<template>
    <div style="width: 90%; margin: 0 auto" class="pt-4">
        <div
            class="font-bold font-24 pb-2"
            style="border-bottom: 1px solid #ddd; padding-top: 24px"
        >
            Hành trình xe chạy
        </div>
        <p class="font-20">
            {{ getDataThongTinThemCuaXe.hanhTrinhChay || "" }}
        </p>
        <div
            class="font-bold font-24 pb-2"
            style="border-bottom: 1px solid #ddd; padding-top: 38px"
        >
            Thời gian dự kiến
        </div>
        <p class="font-20">
            Dự kiến tới {{ getDataThongTinThemCuaXe.tenBenDen }} lúc :
            {{
                moment(getDataThongTinThemCuaXe.thoiGianDenBen).format(
                    "HH:mm DD/MM/yyyy"
                )
            }}
        </p>
        <div
            class="font-bold font-24 pb-2"
            style="border-bottom: 1px solid #ddd; padding-top: 38px"
        >
            Điều khoản bán vé
        </div>
        <div>
            <p
                class="font-20"
                v-for="(
                    item, index
                ) in getDataThongTinThemCuaXe.danhSachDieuKhoan"
                :key="index"
            >
                {{ `- ${item}` }}
            </p>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    components: {},
    props: {},
    computed: {
        ...mapGetters("chiTietChuyenDi", [
            "getDataThongTinThemCuaXe",
            "getTabDangChon",
        ]),
        ...mapGetters("timKiemChuyenDi", ["getDialogChiTietChuyenDi"]),
    },
    methods: {
        ...mapActions("chiTietChuyenDi", ["layThongTinThemCuaXe"]),
    },
    watch: {
        getDialogChiTietChuyenDi() {
            if (this.getDialogChiTietChuyenDi && this.getTabDangChon == 3) {
                this.layThongTinThemCuaXe();
            }
        },
    },
};
</script>
<style scoped>
</style>
<style lang='scss' scoped>
</style>