<template>
    <div
        style="width: 80%; margin: 0 auto"
        class="pt-4"
        v-if="getDataThongTinDichVuXe.length > 0"
    >
        <div
            class="row pt-2 pb-3"
            v-for="(item, index) in getDataThongTinDichVuXe"
            :key="index"
            style="border-bottom: 1px solid #ddd"
        >
            <div class="shrink pr-3">
                <i
                    :class="`mdi mdi-${item.icon} primary--text`"
                    style="font-size: 32px"
                ></i>
            </div>
            <div class="grow">
                <div class="font-24">{{ item.tenDichVu }}</div>
                <div class="font-20">{{ item.moTaDichVu }}</div>
            </div>
        </div>
    </div>
    <div v-else class="text-xs-center font-24 pt-5" style="opacity: 0.54">
        Không có dữ liệu!
    </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
    props: {
        IdXe: { type: [Number, String], default: null || "" },
    },
    computed: {
        ...mapGetters("chiTietChuyenDi", [
            "getDataThongTinDichVuXe",
            "getTabDangChon",
        ]),
        ...mapGetters("timKiemChuyenDi", ["getDialogChiTietChuyenDi"]),
    },
    methods: {
        ...mapActions("chiTietChuyenDi", ["layThongTinDichVuXe"]),
    },
    watch: {
        getDialogChiTietChuyenDi() {
            if (this.getDialogChiTietChuyenDi && this.getTabDangChon == 1) {
                this.layThongTinDichVuXe();
            }
        },
    },
};
</script>