<template>
    <div style="padding-left: 84px; padding-right: 84px">
        <div
            class="row font-24 align-center pt-4 justify-center"
            style="width: 880px; min-width: 880px"
        >
            <div
                style="
                    width: 84px;
                    height: 84px;
                    border: 1px solid #ddd;
                    border-radius: 50%;
                "
                :style="`background-image: url(${
                    item.nhaXe_LogoNhaXe || ''
                });background-repeat: round;`"
                class="mr-3"
            ></div>
            <div class="xs8">
                <div class="font-24">
                    {{ item.nhaXe_TenNhaXe || item.tenDoanhNghiep }}
                </div>
                <div class="font-16">
                    {{ item.nhaXe_SoDienThoai || item.doanhNghiep_SoDienThoai }}
                    - Liên hệ tổng đài
                </div>
            </div>
        </div>
        <div class="row justify-center pt-3">
            <div class="xs3">
                <div class="row font-24 align-center primary--text">
                    <i
                        class="icon-style pr-2 mdi mdi-timelapse"
                        style="font-size: 50px"
                    ></i>
                    <div class="text-xs-left">
                        <div class="font-32 font-bold">
                            {{ moment(item.gioXuatBenDuKien).format("HH:mm") }}
                        </div>
                        <div class="font-24">
                            {{
                                moment(item.gioXuatBenDuKien).format(
                                    "DD/MM/yyy"
                                )
                            }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="xs3">
                <div class="column align-center">
                    <div
                        class="font-bold pb-1 pl-2 primary--text"
                        style="font-size: 32px"
                    >
                        {{ `${$MoneyFormat(item.giaVeMin)}đ` }}
                    </div>
                    <div
                        class="font-24"
                        :style="`color:${item.maMauTrangThai}`"
                    >
                        {{ item.tenTrangThaiChuyenDi }}
                    </div>
                </div>
            </div>
        </div>
        <div class="row font-24 justify-center py-5">
            <div style="border: 1px solid #efefef; width: 70%"></div>
        </div>
        <div class="row">
            <div class="xs4 pl-5">
                <div class="row font-24 align-center">
                    <i class="icon-style pr-2 mdi mdi-phone"></i>
                    <div class="font-24">{{ item.xe_SoDienThoai }}</div>
                </div>
                <div class="row font-24 align-center">
                    <i class="icon-style pr-2 mdi mdi-counter"></i>
                    <div class="font-24">{{ item.xe_BienSoXe }}</div>
                </div>
            </div>
            <div class="xs4 pl-5">
                <div class="row font-24 align-center">
                    <i class="icon-style pr-2 mdi mdi-seat"></i>
                    <div class="font-24">
                        {{
                            `${
                                item.soGheNgoiTrong + item.soGiuongNamTrong || 0
                            } Chỗ trống`
                        }}
                    </div>
                </div>
                <div class="row font-24 align-center">
                    <i class="icon-style pr-2 mdi mdi-bus"></i>
                    <div class="font-24">{{ item.loaiXe_TenLoaiXe }}</div>
                </div>
            </div>
            <div class="xs4 pl-5">
                <div class="row font-24 align-center">
                    <i class="icon-style pr-2 mdi mdi-map-marker-radius"></i>
                    <div class="font-24">
                        {{
                            item.loTrinh
                                ? item.loTrinh.diemDung.tenDiemDung
                                : ""
                        }}
                    </div>
                </div>
                <div class="row font-24 align-center">
                    <i class="icon-style pr-2 mdi mdi-parking"></i>
                    <div class="font-24">
                        {{ `Xe đỗ tại ${item.viTriDoXe || ""}` }}
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 340px; padding-top: 48px">
            <QuangCaoVue
                :class="
                    item.xe_HinhAnh
                        ? item.xe_HinhAnh.length == 0
                            ? 'boundary'
                            : ''
                        : ''
                "
                Height="100%"
                :ShowNavButton="true"
                :DataSource="item.xe_HinhAnh"
            />
        </div>
        <div
            style="opacity: 60%; font-style: italic"
            class="font-11 text-xs-right pt-2 pb-5"
        >
            Hình ảnh được cung cấp bởi đơn vị vận tải hoặc nhà xe
        </div>
    </div>
</template>
<script>
import QuangCaoVue from "../QuangCao";
import { mapActions, mapGetters } from "vuex";
export default {
    components: {
        QuangCaoVue,
    },
    computed: {
        ...mapGetters("chiTietChuyenDi", {
            getThongTinXeDangChon: "getThongTinXeDangChon",
            item: "getDataThongTinXe",
            getTabDangChon:"getTabDangChon",
        }),
        ...mapGetters("timKiemChuyenDi", ["getDialogChiTietChuyenDi"]),
    },
    mounted() {
        this.layThongTinXe();
    },
    watch: {
        getDialogChiTietChuyenDi() {
            if (this.getDialogChiTietChuyenDi && this.getTabDangChon == 0) {
                this.layThongTinXe();
            }
        },
    },
    methods: {
        ...mapActions("chiTietChuyenDi", ["layThongTinXe"]),
    },
};
</script>
<style lang="scss" scoped>
.icon-style {
    font-size: 32px;
}
</style>
